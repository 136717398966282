:root {
  /* Breakpoints */
  /* @media (min-width: --md) { ... } */
  --xs: 475px;
  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;

  /* Colors */
  /* Colors - Backgrounds */
  --color-primary: rgba(46, 204, 113, 1);
  --color-secondary: rgba(0, 121, 203, 1);
  --color-accent-green: rgba(46, 204, 113, 1);
  --color-accent-blue: rgba(0, 123, 206, 1);
  --color-accent-yellow: rgba(241, 196, 15, 1);
  --color-accent-red: rgba(218, 88, 74, 1);
  --color-accent-purple: rgba(180, 122, 235, 1);

  /* Colors - Fonts */
  --color-text-green: rgba(0, 137, 53, 1);
  --color-text-blue: rgba(0, 121, 203, 1);
  --color-text-white: rgba(255, 255, 255, 1);
  --color-text-gray: rgba(48, 51, 50, 1);
  --color-text-black: rgba(0, 0, 0, 1);

  /* Colors - Status */
  --color-success: rgba(46, 204, 113, 1);
  --color-error: rgba(218, 88, 74, 1);

  /* Colors - Other Colors */
  --color-white: rgba(255, 255, 255, 1);
  --color-light-gray: rgba(242, 242, 242, 1);
  --color-medium-gray: rgba(166, 166, 166, 1);
  --color-dark-gray: rgba(48, 51, 50, 1);
  --color-black: rgba(0, 0, 0, 1);
  --color-faded-green: rgba(46, 204, 113, 0.4);
  --color-faded-blue: rgba(0, 121, 203, 0.4);
  --color-faded-red: rgba(218, 88, 74, 0.4);

  /* Colors - Gradients */
  --color-gradient-green: radial-gradient(
    150.32% 297.43% at -40.06% 195.99%,
    rgba(0, 121, 203, 0.9) 0%,
    rgba(46, 204, 113, 0.9) 100%
  );
  --color-gradient-blue: radial-gradient(
    150.32% 297.43% at -40.06% 195.99%,
    rgba(46, 204, 113, 0.9) 0%,
    rgba(0, 121, 203, 0.9) 100%
  );
  --color-gradient-blue-lighter: radial-gradient(
    150.32% 297.43% at -40.06% 195.99%,
    rgba(46, 204, 113, 0.75) 0%,
    rgba(0, 121, 203, 0.75) 100%
  );

  /* Header/Footer/Modal/Menu Measurements*/
  --default-header-height: 70px;
  --header-height: var(--header-height);

  /* Filter height */
  --app-filter-sm-height: 49px;
  --app-filter-md-height: 49px;

  /* Screen height on mobile */
  /* https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser/37113430#37113430 */
  --app-height: calc(100vh - calc(100vh - 100%));
}
