@import 'tailwindcss-primeui';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('@angular/cdk/overlay-prebuilt.css');
@import url('libs/ui-styles/src/styles/vars.css');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,700,900');

:root {
  --header-height: calc(
    env(safe-area-inset-top) + var(--default-header-height)
  );
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  overscroll-behavior-y: none;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  @media (max-width: 768px) {
    width: 1px;
  }
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.modal_panel {
  min-width: 100%;
  margin-left: unset;
  margin-right: unset;
  @media (min-width: 640px) {
    min-width: unset;
  }
}

.fullscreen_modal_panel {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.modal_backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}

.cdk-global-overlay-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cdk-dialog-container {
  background: white;
  border-radius: 4px;
  padding: 24px;
}

/* Used by [padspinDialogTitle] directive */
:root {
  --padspin_dialog_title_height: 30px;
  --padspin_dialog_actions_height: 52px;
}

.padspin_dialog_title {
  display: block;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0 0 1px;
}
/* Used by the [padspinDialogContent] directive */
.padspin_dialog_content {
  display: block;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  overflow: auto;
  max-height: 65vh;
  @media (min-height: 850px) {
    max-height: calc(
      85vh - var(--padspin_dialog_actions_height) -
        var(--padspin_dialog_title_height)
    );
  }
}
/* Used by the [padspinDialogActions] directive */
.padspin_dialog_actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-height: var(--padspin_dialog_actions_height);
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent;
  gap: 10px;
}

/* Start Material replacement styles */
body {
  font-family: system-ui, sans-serif;
  font-size: 1em;
  font-weight: unset;
  line-height: 20px;
}

h1 {
  font-size: 1.5em;
  font-weight: 400;
  line-height: 2em;
  font-family: system-ui, sans-serif;
  letter-spacing: unset;
  margin: 0 0 16px;
}

h2 {
  font-size: 1.25em;
  font-weight: 500;
  line-height: 2em;
  font-family: system-ui, sans-serif;
  letter-spacing: unset;
  margin: 0 0 16px;
}

h3 {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.75em;
  font-family: system-ui, sans-serif;
  letter-spacing: unset;
  margin: 0 0 16px;
}

h4,
h5,
h6 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: unset;
}

p {
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
  font-family: system-ui, sans-serif;
  margin: 0 0 12px;
}
/* End Material replacement styles */
